<template>
  <div class="OTPemailhasnotarrived">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./OTPPage" class="page_link">OTP Page</router-link> > The OTP email has not arrived
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. What should I do if the OTP email has not arrived?</h1>
      <h3>Answer</h3>
      <p class="answer">
        The OTP email is sent to the email address registered in the SSO system.<br />
        You will receive an email when you log in regularly an email from "@akamai.com".<br />
        Please set it to receive.<br />
        Subject : [SUZUKI MOTOR CORPORATION] Additional Validation Step Triggered<br /><br />
        If you do not receive the OTP email, check the following:<br />
        ・Look in their email spam folder. The authentication email may have been sent to spam.<br />
        ・Please permit the email domain of "@akamai.com".<br />
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
      <img src="/arrow_back_white.png" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="./InvalidAuthenticationCode"><img id="forward" src="/arrow_forward.png" v-on:mouseover="imageForwardChange" v-on:mouseleave="imageForwardReturn" /></router-link>
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

#forward {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

#back {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
